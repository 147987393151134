import moment from "moment";

export const SKUs = {
  WekaDPO: "WEKA-DPO",
  WekaDTO: "WEKA-DTO",
  WekaDEO: "WEKA-DEO",
  //flash capacity types
  None: "None",
  WekaXPS: "WEKA-XPS",
  WekaXCL: "WEKA-XCL",
  WekaXOS: "WEKA-XOS",
  WekaWPSW: "WEKA-WPSW",
};

export const TB_TO_GB = 1000;
export const TB_TO_B = 1000000000000;

export function isEntitlementExpired(ent) {
  return (
    ent.created_at &&
    ent.seconds &&
    moment(ent.created_at).add(ent.seconds, "seconds").isBefore(moment())
  );
}

export const EnforcementTypes = {
  Subscription: "Subscription",
  Evaluation: "Evaluation",
  Trial: "Trial",
};

export function getPublishedDate(release) {
  return release.published_at ? release.published_at : release.created_at;
}
