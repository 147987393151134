var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body text-center"},[(_vm.loading)?_c('weka-spinner'):(_vm.error)?_c('error-box',{attrs:{"error":_vm.error,"title":"Oh no"}}):_c('div',[(_vm.ltsRelease)?_c('div',[_c('p',{staticStyle:{"color":"#888"}},[_vm._v("Latest LTS Release")]),_c('p',[_c('router-link',{staticClass:"btn btn-lg btn-success",staticStyle:{"width":"220px"},attrs:{"to":{
              name: 'releases:release',
              params: { release: _vm.ltsRelease.id },
            },"tag":"a"}},[_c('div',[_vm._v(" "+_vm._s(_vm.ltsRelease.id)+" ")]),_c('div',{staticStyle:{"font-size":"75%","color":"#e3ffe3","font-weight":"300"}},[_vm._v(" Released "+_vm._s(_vm._f("moment")(_vm.getPublishedDate(_vm.ltsRelease),"from"))+" ")])])],1),_c('div',{staticClass:"small"},[_c('a',{attrs:{"href":`${_vm.ltsDocsUrl}`,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"fa-fw",attrs:{"icon":['fas', 'arrow-up-right-from-square']}}),_vm._v(" Documentation ")],1)])]):_vm._e(),(_vm.innovationRelease)?_c('div',{staticStyle:{"margin-top":"5px"}},[_c('p',{staticStyle:{"color":"#888"}},[_vm._v("Latest Innovation Release")]),_c('p',[_c('router-link',{staticClass:"btn btn-lg btn-success",staticStyle:{"width":"220px"},attrs:{"to":{
              name: 'releases:release',
              params: { release: _vm.innovationRelease.id },
            },"tag":"a"}},[_c('div',[_vm._v(" "+_vm._s(_vm.innovationRelease.id)+" ")]),_c('div',{staticStyle:{"font-size":"75%","color":"#e3ffe3","font-weight":"300"}},[_vm._v(" Released "+_vm._s(_vm._f("moment")(_vm.getPublishedDate(_vm.innovationRelease),"from"))+" ")])])],1),_c('div',{staticClass:"small"},[_c('a',{attrs:{"href":"https://docs.weka.io","target":"_blank"}},[_c('font-awesome-icon',{staticClass:"fa-fw",attrs:{"icon":['fas', 'arrow-up-right-from-square']}}),_vm._v(" Documentation ")],1)])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }