<template>
  <div class="panel panel-default">
    <div class="panel-body text-center">
      <weka-spinner v-if="loading"></weka-spinner>

      <error-box v-else-if="error" :error="error" title="Oh no"></error-box>

      <div v-else>
        <div v-if="ltsRelease">
          <p style="color: #888">Latest LTS Release</p>
          <p>
            <router-link
              :to="{
                name: 'releases:release',
                params: { release: ltsRelease.id },
              }"
              class="btn btn-lg btn-success"
              style="width: 220px"
              tag="a"
            >
              <div>
                {{ ltsRelease.id }}
              </div>
              <div style="font-size: 75%; color: #e3ffe3; font-weight: 300">
                Released {{ getPublishedDate(ltsRelease) | moment("from") }}
              </div>
            </router-link>
          </p>
          <div class="small">
            <a :href="`${ltsDocsUrl}`" target="_blank">
              <font-awesome-icon
                :icon="['fas', 'arrow-up-right-from-square']"
                class="fa-fw"
              ></font-awesome-icon>
              Documentation
            </a>
          </div>
        </div>
        <div v-if="innovationRelease" style="margin-top: 5px">
          <p style="color: #888">Latest Innovation Release</p>
          <p>
            <router-link
              :to="{
                name: 'releases:release',
                params: { release: innovationRelease.id },
              }"
              class="btn btn-lg btn-success"
              style="width: 220px"
              tag="a"
            >
              <div>
                {{ innovationRelease.id }}
              </div>
              <div style="font-size: 75%; color: #e3ffe3; font-weight: 300">
                Released
                {{ getPublishedDate(innovationRelease) | moment("from") }}
              </div>
            </router-link>
          </p>
          <div class="small">
            <a href="https://docs.weka.io" target="_blank">
              <font-awesome-icon
                :icon="['fas', 'arrow-up-right-from-square']"
                class="fa-fw"
              ></font-awesome-icon>
              Documentation
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getPublishedDate } from "@/utils.js";

export default {
  name: "latest-release",
  methods: { getPublishedDate },

  data() {
    return {
      innovationRelease: null,
      ltsRelease: null,
      release: null,
      loading: true,
      error: null,
    };
  },

  computed: {
    ltsDocsUrl() {
      const docsVersion = this.ltsRelease.id.split(".").slice(0, 2).join(".");
      return `https://docs.weka.io/v/${docsVersion}`;
    },
    trunk() {
      return this.$store.getters.hasAdminPrivileges ? "" : undefined; // for weka employees only from 'Public Releases' trunk
    },
  },
  async mounted() {
    try {
      const res = await axios.get("/dist/v1/release", {
        params: { public: "yes", trunk: this.trunk, page_size: 10 },
      });
      if (res.data.num_results === 0) {
        this.error = "Could not find the latest release";
      } else {
        if (this.$store.getters.hasAdminPrivileges) {
          // hide limited releases as latest for weka employees
          this.innovationRelease = res.data.objects.find(
            (r) => !r.lts && !r.limited
          );
          this.ltsRelease = res.data.objects.find(
            (r) => r.lts === true && !r.limited
          );
        } else {
          this.innovationRelease = res.data.objects.find((r) => !r.lts);
          this.ltsRelease = res.data.objects.find((r) => r.lts === true);
        }
      }
    } catch (error) {
      this.error = error;
    }
    this.loading = false;
  },
};
</script>
